<template>
  <div>
    <!-- <div>
      <loading :active.sync="isLoader" loader="spinner" color="#36304a" :width="70"></loading>
    </div> -->
      
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-card-header class="mb-3">
      <b-row>
        <b-col> <i class="icon-menu mr-1"></i>Details Report</b-col>
      </b-row>
    </b-card-header>
    <b-card>
      <div class="row">
        <b-col sm="3">
          <b-form-group>
            <label for="from-date">From Date :</label>
            <b-form-input
              type="date"
              v-model="accountsData.FromDate"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col sm="3">
          <b-form-group>
            <label for="to-date">To Date :</label>
            <b-form-input
              type="date"
              v-model="accountsData.ToDate"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group>
            <label for="from-date">Select Cinema Name</label>
            <b-form-select
              v-model="accountsData.selectedCinema"
              id="cinemas"
              :plain="true"
              text-field="CinemaName"
              value-field="CinemaID"
              :options="$store.getters['common/getAllCinemas']"
              @change="getMovieByCinemaID"
            >
              <template slot="first">
                <option :value="'0'" disabled>-- Select Cinema --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col sm="3">
          <b-form-group>
            <label for="from-date">Select Payment Mode </label>
            <b-form-select
              required
              v-model="accountsData.selectedPaymentMode"
              id="cinemas"
              :plain="true"
              text-field="Movie"
              value-field="MovieID"
              :options="paymentList"
            >
              <template slot="first">
                <option :value="'0'" disabled>-- Select Payment Mode --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </div>
      <div class="row">
        <b-col sm="12" class="text-center">
          <b-button
            class="ml-2"
            type="submit"
            size="sm"
            variant="primary"
            @click="getDetailsAccountReport"
            >Search</b-button
          >
          <b-button
            class="ml-2"
            type="submit"
            size="sm"
            variant="primary"
            @click.prevent="resetForm"
            >Reset</b-button
          >
          <b-button
            class="ml-2"
            type="submit"
            size="sm"
            variant="primary"
            @click="ExportAccountExcel"
            >Export to Excel</b-button
          >
          <!-- <b-button class="ml-5" @click="exportPdf">Export PDF</b-button> -->
        </b-col>
      </div>
    </b-card>
    <hr />
    <b-card>
      <b-card>
        <div style="width: 100%;height: 100%;position: absolute;background: white;z-index: 1000;" v-if="isLoader"><CircleLoader  />
    </div>
        <v-client-table :data="data1" :columns="columns1" :options="options1">
        </v-client-table>

        <b-row>
          <p class="mx-3">
            Base Price + Vat on Base Tkt-(Bank Charges 2%+10% of VAT on bank
            charges)
          </p>
        </b-row>
        <hr />
        <!-- <v-client-table
          class="my-2"
          :data="data2"
          :columns="columns2"
          :options="options2"
        >
        </v-client-table> -->
        <!-- <Common-Pagination
          v-if="data2.length > 0"
          :headings="column"
          :data="data2"
        /> -->
        <div class="table-wrapper-scroll-y my-custom-scrollbar">
          <table
            class="table table-striped table-bordered"
            style="overflow-x: auto; width: 200px; height: 100px"
          >
            <thead style="color: white">
              <tr>
                <th v-for="(head, index) of columnhead" :key="index">
                  {{ head }}
                </th>
              </tr>
            </thead>
            <tbody v-if="displayedRecords.length > 0">
              <tr v-for="(record, index) of displayedRecords" :key="index">
                <td>{{ record.Sr }}</td>
                <td>{{ record.OrderID }}{{ getUpdate }}</td>
                <td>{{ record.Theatre }}</td>
                <td>{{ record.ShowDate }}</td>
                <td>{{ record.ShowTime }}</td>
                <td>{{ record.ScreenName }}</td>
                <td>{{ record.payment_mode }}</td>
                <td>{{ record.CardNumber }}</td>
                <td>{{ record.TransactionDate }}</td>
                <td>{{ record.seatnames }}</td>
                <td>{{ record.NoOfTickets }}</td>
                <td>{{ record.BaseTicketPrice }}</td>
                <td>{{ record.VATONBTP }}</td>
                <td>{{ record.Wanasa_service_charge }}</td>
                <td>{{ record.VAT_Wanasa_service_charge }}</td>
                <td>{{ record.Total_amount_from_cust }}</td>
                <td>{{ record.bank_charges_cineco }}</td>
                <td>{{ record.bank_charges_wanasa }}</td>
                <td>{{ record.VAT_bank_charges_cineco }}</td>
                <td>{{ record.scbconveniencefee }}</td>
                <td>{{ record.vatonscbconveniencefee }}</td>
                <td>{{ record.payable_cineco }}</td>
              </tr>
              <tr>
                <td colspan="10"><b>Total</b></td>
                <td>{{ TotalNoOfTickets }}</td>
                <td>{{ TotalBaseTicketPrice.toFixed(3) }}</td>
                <td>{{ TotalVATONBTP.toFixed(3) }}</td>
                <td>{{ TotalWanasa_service_charge.toFixed(3) }}</td>
                <td>{{ TotalVAT_Wanasa_service_charge.toFixed(3) }}</td>
                <td>{{ TotalTotal_amount_from_cust.toFixed(3) }}</td>
                <td>{{ Totalbank_charges_cineco.toFixed(3) }}</td>
                <td>{{ Totalbank_charges_wanasa.toFixed(3) }}</td>
                <td>{{ TotalVAT_bank_charges_cineco.toFixed(3) }}</td>
                <td>{{ Totalscbconveniencefee.toFixed(3) }}</td>
                <td>{{ Totalvatonscbconveniencefee.toFixed(3) }}</td>
                <td>{{ Totalpayable_cineco.toFixed(3) }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="16"><center>Data Not Found!</center></td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination
          :records="records.length"
          v-model="page"
          :per-page="perPage"
          @paginate="callback"
        >
        </pagination>
      </b-card>
    </b-card>
  </div>
  </div>
</template>

<script>
import { Switch as cSwitch } from "@coreui/vue";
import { Event } from "vue-tables-2";
import AccountReportService from "@/services/AccountReportService";
import Pagination from "vue-pagination-2";
// import CommonPagination from "./CommonPagination.vue";
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
import CircleLoader from "../../SchedulesDetails/Circle.vue";

// jspdf-autotable
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { BASE64IMAGE } from "@/helper/baseImage";
import {
  dashboardName,
  pdfFillColor,
  WANASHAdashboardName,
} from "@/helper/constant";
import XLSX from "xlsx";
import wanasaImage from "@/helper/wanasaImage";

import ReportsService from "@/services/ReportsService";

export default {
  name: "AccountsReport",
  components: {
    Event,
    cSwitch,
    // CommonPagination,
    Pagination,
    // Loading,
    CircleLoader,
  },
  watch: {},
  data: function () {
    return {
      isLoader: false,
      TotalNoOfTickets: 0,
      TotalBaseTicketPrice: 0,
      TotalVATONBTP: 0,
      TotalWanasa_service_charge: 0,
      TotalVAT_Wanasa_service_charge: 0,
      TotalTotal_amount_from_cust: 0,
      Totalbank_charges_cineco: 0,
      Totalbank_charges_wanasa: 0,
      TotalVAT_bank_charges_cineco: 0,
      Totalscbconveniencefee: 0,
      Totalvatonscbconveniencefee: 0,
      Totalpayable_cineco: 0,
      page: 1,
      perPage: 10,
      records: [],
      counter: 21,

      Tot: 0,
      transactionDateReport: [],
      accountsData: {
        FromDate: "",
        ToDate: "",
        selectedCinema: 0,
        selectedPaymentMode: 0,
      },
      paymentList: ["All", "Debit Card", "Credit Card", "SCB"],
      cinemaList: [],
      movieList: [],
      summaryData: [],

      columns1: [
        "total_amount",
        "scb_tickets",
        "normal_tickets",
        "total_tickets",
        "cineco_amount",
        "pg_amount",
      ],
      data1: [],
      options1: {
        headings: {
          total_amount: "Total Amount",
          scb_tickets: "SCB Tickets",
          normal_tickets: "Normal Tickets",
          total_tickets: "Total Tickets",
          cineco_amount: "Cineco Amount(BHD)",
          pg_amount: "Payment Gateway Amount(BHD)",
        },

        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        perPage: 10,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      //Book New Transcation
      columns2: [
        "OrderID",
        "Theatre",
        "ShowDate",
        "ShowTime",
        "ScreenName",
        // "MovieName",
        "payment_mode",
        "CardNumber",
        "TransactionDate",
        "seatnames",
        "NoOfTickets",
        "BaseTicketPrice",
        "VATONBTP",
        "Wanasa_service_charge",
        "VAT_Wanasa_service_charge",
        "Total_amount_from_cust",
        "bank_charges_cineco",
        "bank_charges_wanasa",
        "VAT_bank_charges_cineco",
        "scbconveniencefee",
        "vatonscbconveniencefee",
        "payable_cineco",
      ],
      columnhead: [
        "SN.",
        "Order ID",
        "Mall Name",
        "Show Date",
        "Show Time",
        "Screen Name",
        "Payment Mode",
        "Card Number",
        "Transaction Date",
        "Seat Names",
        "No. of Tickets",
        "Base Ticket Price",
        "Vat on Base Ticket Price",
        "Wanasa Service Charges",
        "Vat On Service Charges",
        "Total Amount From Customer",
        "Bank Charges for Cineco",
        "Bank Charges WanasaTime",
        "VAT of Bank Charges for Base Ticket Price",
        "SCB Convenience Fee",
        "VAT(10%) on SCB Convenience Fee",
        "Net Amount Payable to Cineco",
      ],
      data2: [],
      finalArr: [],
      ExcelArr: [],
      options2: {
        headings: {
          OrderID: "Order ID",
          Theatre: "Mall Name",
          ShowDate: "Show Date",
          ShowTime: "Show Time",
          ScreenName: "Screen Name",
          payment_mode: "Payment Mode",
          CardNumber: "Card Number",
          TransactionDate: "Transaction Date",
          seatnames: "Seat Names",
          NoOfTickets: "No. of Tickets",
          BaseTicketPrice: "Base Ticket Price",
          VATONBTP: "Vat on Base Ticket Price",
          Wanasa_service_charge: "Wanasa Service Charges",
          VAT_Wanasa_service_charge: "Vat On Service Charges",
          Total_amount_from_cust: "Total Amount From Customer",
          bank_charges_cineco: "Bank Charges for Cineco",
          bank_charges_wanasa: "Bank Charges WanasaTime",
          VAT_bank_charges_cineco: "VAT of Bank Charges for Base Ticket Price",
          scbconveniencefee: "SCB Convenience Fee",
          vatonscbconveniencefee: "VAT(10%) on SCB Convenience Fee",
          payable_cineco: "Net Amount Payable to Cineco",
        },

        sortable: [],
        filterable: [],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        perPage: 10,
        page: 1,
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll",
        },
      },
      //Book New Transcation

      useVuex: false,
      theme: "bootstrap4",
      theme1: "bootstrap4",
      theme2: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
    };
  },
  beforeMount() {
    let payload = {};
    // alert(this.secureStore.get("TTURO"));
    if (this.secureStore.get("TTURO") == "Admin" || this.secureStore.get("TTURO") =="Finance") {
      payload = {
        OrgID: 3,
      };
    }
    this.$store.dispatch("common/setAllCinemas", payload);
    // this.$store.dispatch("common/setAllCinemas");
    this.getFromToDate();
    this.getDetailsAccountReport();
  },
  mounted() {},
  methods: {
    callback: function (page) {
      this.TotalNoOfTickets = 0;
      this.TotalBaseTicketPrice = 0;
      this.TotalVATONBTP = 0;
      this.TotalWanasa_service_charge = 0;
      this.TotalVAT_Wanasa_service_charge = 0;
      this.TotalTotal_amount_from_cust = 0;
      this.Totalbank_charges_cineco = 0;
      this.Totalbank_charges_wanasa = 0;
      this.TotalVAT_bank_charges_cineco = 0;
      this.Totalscbconveniencefee = 0;
      this.Totalvatonscbconveniencefee = 0;
      this.Totalpayable_cineco = 0;
    },
    getMovieByCinemaID() {
      if (this.accountsData.selectedCinema) {
        let payload = {
          screenID: this.accountsData.selectedCinema,
        };
        this.movieList = [];
        AccountReportService.getMovieByCinemaID(payload)
          .then((response) => {
            const { data } = response;
            if (
              data.Status &&
              (data.movieList.length > 0 || data.movieList.length > 0)
            ) {
              this.movieList = data.movieList;
            } else {
              this.showMessageSnackbar(data.Message);
            }
          })
          .catch((error) => {
            console.log("Catch on AddUser-getRoles Error: ", error);
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          });
      }
    },
    getFromToDate() {
      var today = new Date();
      var firstDay = "";
      var lastDay = "";
      // firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      firstDay = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      var firstDay_dd = String(firstDay.getDate()).padStart(2, "0");
      var firstDay_mm = String(firstDay.getMonth() + 1).padStart(2, "0"); //January is 0!
      var firstDay_yyyy = firstDay.getFullYear();

      var lastDay_dd = String(lastDay.getDate()).padStart(2, "0");
      var lastDay_mm = String(lastDay.getMonth() + 1).padStart(2, "0"); //January is 0!
      var lastDay_yyyy = lastDay.getFullYear();

      firstDay = firstDay_yyyy + "-" + firstDay_mm + "-" + firstDay_dd; //2018-06-01
      lastDay = lastDay_yyyy + "-" + lastDay_mm + "-" + lastDay_dd; //2018-06-01

      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = dd + "-" + mm + "-" + yyyy; //2018-06-01
      this.FromDate = today;
      this.accountsData.FromDate = firstDay;
      this.accountsData.ToDate = firstDay;
      // this.accountsData.ToDate = lastDay;
    },
    ExportAccountExcel: function () {
      console.log("this.ExcelArr=", this.ExcelArr);
      if (this.ExcelArr.length > 0) {
        var dataOne = XLSX.utils.json_to_sheet(this.ExcelArr);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, dataOne, "Details");
        let ExcelDate = new Date().toDateString();
        if (this.accountsData.FromDate) {
          ExcelDate = new Date(this.accountsData.FromDate).toDateString();
        } else {
          ExcelDate = new Date().toDateString();
        }
        XLSX.writeFile(
          wb,
          `${WANASHAdashboardName.toUpperCase()}_Cineco_Details_Report_${ExcelDate}.xlsx`
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    exportPdf: async function () {
      if (this.ExcelArr.length > 0) {
        var keys = [];
        for (var key in this.ExcelArr[0]) {
          keys.push(key);
        }
        console.log(keys, "keys");

        let mainArray = [];
        this.ExcelArr.map((x) => {
          let xdata = x;
          let tempArray = [];
          for (let i = 0; i <= Object.keys(xdata).length; i++) {
            tempArray.push(x[Object.keys(xdata)[i]]);
          }
          console.log(tempArray, "temoa");
          mainArray.push(tempArray);
        });
        var imgData = wanasaImage;
        let ExcelDate = new Date().toDateString();
        if (this.accountsData.FromDate) {
          ExcelDate = new Date(this.accountsData.FromDate).toDateString();
        } else {
          ExcelDate = new Date().toDateString();
        }

        var doc = new jsPDF("l", "px", [1000, 600]);
        doc.addImage(imgData, "JPEG", 265, 20, 150, 60);

        doc.text(
          260,
          100,
          `${WANASHAdashboardName.toUpperCase()}_Cineco_Details_Report_${ExcelDate}`
        );
        doc.setFontSize(17);
        doc.autoTable({
          head: [keys],
          theme: "grid",
          body: mainArray,
          margin: { top: 120 },
          headStyles: {
            fillColor: pdfFillColor,
          },
        });
        doc.save(
          `${WANASHAdashboardName.toUpperCase()}_Details_Report_${new Date().toDateString()}.pdf`
        );
      } else {
        this.showMessageSnackbar("No data available");
      }
    },
    getDetailsAccountReport() {
      this.data1 = [];
      this.data2 = [];
      this.finalArr = [];
      this.ExcelArr = [];
      this.isLoader = true;
      let payload = {
        FromDate: this.accountsData.FromDate,
        ToDate: this.accountsData.ToDate,
        CinemaID: this.accountsData.selectedCinema,
        PaymentMode: this.accountsData.selectedPaymentMode,
      };

      AccountReportService.getDetailAccountsReport(payload)
        .then((response) => {
          const { data } = response;
          if (
            data.Status &&
            (data.ReportsDetails.length > 0 || data.TotalDetails.length > 0)
          ) {
            this.data2 = data.ReportsDetails;
            this.data1 = data.TotalDetails;
            this.records = [];
            if (this.data2.length > 0) {
              this.data2.forEach((element) => {
                this.records.push(element);
              });
              let finalArr = this.finalArr;
              let NewArr = this.data2.map((x) => {
                finalArr.push({
                  "SN.": x["Sr"],
                  "Order ID": x["OrderID"],
                  "Mall Name": x["Theatre"],
                  "Show Date": x["ShowDate"],
                  "Show Time": x["ShowTime"],
                  "Screen Name": x["ScreenName"],
                  "Payment Mode": x["payment_mode"],
                  "Card Number": x["CardNumber"],
                  "Transaction Date": x["TransactionDate"],
                  "Seat Names": x["seatnames"],
                  "No. of Tickets": x["NoOfTickets"],
                  "Base Ticket Price": x["BaseTicketPrice"],
                  "Vat on Base Ticket Price": x["VATONBTP"],
                  "Wanasa Service Charges": x["Wanasa_service_charge"],
                  "Vat On Service Charges": x["VAT_Wanasa_service_charge"],
                  "Total Amount From Customer": x["Total_amount_from_cust"],
                  "Bank Charges for Cineco": x["bank_charges_cineco"],
                  "Bank Charges WanasaTime": x["bank_charges_wanasa"],
                  "VAT of Bank Charges for Base Ticket Price":
                    x["VAT_bank_charges_cineco"],
                  "SCB Convenience Fee": x["scbconveniencefee"],
                  "VAT(10%) on SCB Convenience Fee": x["vatonscbconveniencefee"],
                  "Net Amount Payable to Cineco": x["payable_cineco"],
                });
              });
              this.ExcelArr = finalArr;
            }
          } else {
            this.showMessageSnackbar(data.Message);
          }
          this.isLoader = false;
        })
        .catch((error) => {
          console.log("Catch on AddUser-getRoles Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },

    resetForm() {
      Object.keys(this.accountsData).forEach(
        (key) => (this.accountsData[key] = "")
      );
      this.getFromToDate();
      this.accountsData["selectedCinema"] = 0;
      this.accountsData["selectedPaymentMode"] = 0;
    },
    // Show Error Message
    showMessageSnackbar: function (message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
  },
  computed: {
    displayedRecords() {
      this.TotalNoOfTickets = 0;
      this.TotalBaseTicketPrice = 0;
      this.TotalVATONBTP = 0;
      this.TotalWanasa_service_charge = 0;
      this.TotalVAT_Wanasa_service_charge = 0;
      this.TotalTotal_amount_from_cust = 0;
      this.Totalbank_charges_cineco = 0;
      this.Totalbank_charges_wanasa = 0;
      this.TotalVAT_bank_charges_cineco = 0;
      this.Totalscbconveniencefee = 0;
      this.Totalvatonscbconveniencefee = 0;
      this.Totalpayable_cineco = 0;
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      return this.records.slice(startIndex, endIndex);
      this.records.slice(startIndex, endIndex);
      var newArray = [];

      this.records.forEach((value) => {
        var exists = false;
        newArray.forEach((val2) => {
          if (value.OrderID == val2.OrderID) {
            exists = true;
          }
        });
        if (exists == false && value.OrderID != "") {
          newArray.push(value);
        }
      });

      this.records = newArray;

      console.log("this.records=>", this.records);
      return newArray.slice(startIndex, endIndex);
    },
    getUpdate() {
      // this.callback();
      this.displayedRecords.forEach((element) => {
        this.TotalNoOfTickets =
          parseInt(this.TotalNoOfTickets) + parseInt(element.NoOfTickets);
        this.TotalBaseTicketPrice =
          parseFloat(this.TotalBaseTicketPrice) +
          parseFloat(element.BaseTicketPrice);
        this.TotalVATONBTP =
          parseFloat(this.TotalVATONBTP) + parseFloat(element.VATONBTP);
        this.TotalWanasa_service_charge =
          parseFloat(this.TotalWanasa_service_charge) +
          parseFloat(element.Wanasa_service_charge);
        this.TotalVAT_Wanasa_service_charge =
          parseFloat(this.TotalVAT_Wanasa_service_charge) +
          parseFloat(element.VAT_Wanasa_service_charge);
        this.TotalTotal_amount_from_cust =
          parseFloat(this.TotalTotal_amount_from_cust) +
          parseFloat(element.Total_amount_from_cust);
        this.Totalbank_charges_cineco =
          parseFloat(this.Totalbank_charges_cineco) +
          parseFloat(element.bank_charges_cineco);
        this.Totalbank_charges_wanasa =
          parseFloat(this.Totalbank_charges_wanasa) +
          parseFloat(element.bank_charges_wanasa);
        this.TotalVAT_bank_charges_cineco =
          parseFloat(this.TotalVAT_bank_charges_cineco) +
          parseFloat(element.VAT_bank_charges_cineco);
        this.Totalscbconveniencefee =
          parseFloat(this.Totalscbconveniencefee) +
          parseFloat(element.scbconveniencefee);
        this.Totalvatonscbconveniencefee =
          parseFloat(this.Totalvatonscbconveniencefee) +
          parseFloat(element.vatonscbconveniencefee);
        this.Totalpayable_cineco =
          parseFloat(this.Totalpayable_cineco) +
          parseFloat(element.payable_cineco);
      });
    },
  },
  created() {
    this.records = [];
    if (this.data2.length > 0) {
      this.data2.forEach((element) => {
        this.records.push(element);
      });
    }
  },
};
</script>

<style>
.pagination_button {
  padding: 8px;
  margin: 2px;
  border-radius: 3px;
  font-size: 1em;
  cursor: pointer;
}
.pagination-row {
  padding: 5px 0;
}

.my-custom-scrollbar {
  position: relative;
  height: 850px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
